/* General Body Styling */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
  color: #333;
  line-height: 1.5;
}

/* Bullet Style for Main Content */
.MuiTypography-body1 {
  margin-left: 20px;
  padding-left: 10px;
  position: relative;
}

.MuiTypography-body1:before {
  content: "•";
  color: #0073e6;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Button Styling */
.MuiButton-containedPrimary,
.MuiButton-outlinedPrimary {
  font-weight: bold;
  padding: 10px 20px;
}

