/* customCarouselStyles.css */
.slider .slick-dots li button {
  width: 20px; /* Width of the dots */
  height: 20px; /* Height of the dots */
  border-radius: 50%; /* Make them round */
  background: #0073e6; /* Color of the dots */
}

.slider .slick-dots li.slick-active button {
  background: #005582; /* Color of the active dot */
}

.slick-dots li button:before {
  color: #0073e6;
}

.slick-dots li.slick-active button:before {
  color: #005582;
}

/* styles.css or a similar CSS file */
.slick-prev,
.slick-next {
  color: black; /* Set the text color to black */
  z-index: 1; /* Ensure buttons are above the images */
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px; /* Optional: Increase font size of the arrows */
  color: black; /* Ensure the arrow color is black */
}

.slick-prev:hover,
.slick-next:hover {
  opacity: 0.7; /* Optional: Change opacity on hover for better UX */
}
